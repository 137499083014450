<template>
    <label class="block">
        <input
                :type="type"
                class="border rounded w-full py-2 px-3 leading-tight outline-none custom_input"
                :class="inputHasError"
                :placeholder="placeholder"
                :value="value"
                @change="onChange"
        />
        <div v-if="error" class="text-red-400 mt-2 text-xs font-medium">
            {{ error }}
        </div>
        <div
                v-if="!error && helpText"
                class="text-red-400 mt-2 text-xs font-medium"
        >
            {{ helpText }}
        </div>
    <label class="input-container">
        <!--    <span v-if="label">{{ label }}</span>-->
        <input
                :value="value"
                :type="type"
                :placeholder="placeholder"
                :readonly="readonly"
                :style="styles"
                maxlength="max"
                @input="onChange"
                @blur="onBlur"
                @focus="onFocus"
                @keydown.enter.prevent
                class="custom_input"
                :maxlength="maxlength"
        />
        <p v-if="helpText" class="help-text">{{ helpText }}</p>
        <span v-if="error" class="message">
      {{ error }}
    </span>
>>>>>>> a6ff25f348da83956c2bdf02502388d86fc53808
    </label>
    </label>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            type: [String, Number],
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
        placeholder: {
            type: String,
            default: '',
        },
        value: {
            type: [String, Number],
            required: true,
        },
        error: {
            type: String,
            default: '',
        },
        helpText: {
            type: String,
            default: '',
        },
    },
    computed: {
        labelClass() {
            return this.error
                ? `text-red-400 ${this.$dm('text-black-800', 'dark:text-slate-50')}`
                : `text-black-800 ${this.$dm('text-black-800', 'dark:text-slate-50')}`;
        },
        isInputDarkOrLightMode() {
            return `${this.$dm('bg-white', 'dark:bg-slate-600')} ${this.$dm(
                'text-slate-700',
                'dark:text-slate-50'
            )}`;
        },
        inputBorderColor() {
            return `${this.$dm('border-black-200', 'dark:border-black-500')}`;
        },
        inputHasError() {
            return this.error
                ? `border-red-200 hover:border-red-300 focus:border-red-300 ${this.isInputDarkOrLightMode}`
                : `hover:border-black-300 focus:border-black-300 ${this.isInputDarkOrLightMode} ${this.inputBorderColor}`;
        },
    },
    methods: {
        onChange(event) {
            this.$emit('input', event.target.value);
        },
        helpText: {
            type: String,
            default: '',
        },
        error: {
            type: String,
            default: '',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        styles: {
            type: Object,
            default: () => {},
        },
        maxlength: {
            type: Number,
            default: 100
        },
    },
    methods: {
        onChange(e) {
            this.$emit('input', e.target.value);
        },
        onBlur(e) {
            this.$emit('blur', e.target.value);
        },
        onFocus(e) {
            this.$emit('focus', e.target.value);
        }
    },
};
</script>
<style scoped lang="scss">
.help-text {
  margin-top: var(--space-micro);
  font-size: var(--font-size-mini);
  color: var(--s-600);
  font-style: normal;
}
.message {
  margin-top: 0 !important;
}
.custom_input{
  height: 40px !important;
  border: 1px solid #A8A8A8 ;
  border-radius: 8px !important;
  /* in case of error */
  &.error {
    border: 1px solid var(--r-400);
  }
}

</style>
