<template>
  <woot-button
    variant="smooth"
    class="label--add"
    icon="add"
    size="tiny"
    @click="addLabel"
  >
    {{ $t('CONTACT_PANEL.LABELS.CONVERSATION.ADD_BUTTON') }}
  </woot-button>
</template>

<script>
export default {
  methods: {
    addLabel() {
      this.$emit('add');
    },
  },
};
</script>

<style lang="scss" scoped>
.label--add {
  margin-bottom: var(--space-micro);
  margin-right: var(--space-micro);
  background: #edecf6!important;
  border: 1px dashed #483a9d !important; /* #0081c9 */
  border-radius: 8px!important;
  height: 25px!important;
  width: fit-content !important;
  font-family: Inter;
  font-style: normal!important;
  font-weight: 500!important;
  font-size: 10px!important;
  line-height: 15px!important;
}
</style>
