<template>
  <div v-if="title === 'without title'">
    <!-- Render chat-option if title is 'withouttitle' -->
    <ul
        v-if="!hideFields"
        class="options"
        :class="{ 'has-selected': !!selected }"
    >
    <chat-option
        v-for="(option, index) in options"
        :key="option.id"
        :action="option"
        :is-selected="isSelected(option)"
        :products-item="productsItems"
        :content-type="contentType"
        :is-popup="isPopup"
        :index="index"
        :activated-index="activatedIndex"
        @index-selected="onSelectIndexOption"
        @click="onClick"
    />
    </ul>
  </div>
  <div v-else class="options-message chat-bubble agent"
       :class="$dm('bg-white', 'dark:bg-slate-700')"
       style="background-color: transparent; box-shadow: none;"
  >
    <div class="card-body">
      <h4 class="title titleTxt" :class="$dm('text-black-900', 'dark:text-slate-50')">
        {{ title }}
      </h4>
      <ul
          v-if="!hideFields"
          class="options"
          :class="{ 'has-selected': !!selected }"
      >
        <chat-option
            v-for="option in options"
            :key="option.id"
            :action="option"
            :is-selected="isSelected(option)"
            :products-item="productsItems"
            :content-type="contentType"
            @click="onClick"
        />
      </ul>
    </div>
  </div>
</template>


<script>
import ChatOption from 'shared/components/ChatOption';
import darkModeMixin from 'widget/mixins/darkModeMixin.js';
import { mapGetters } from 'vuex';
import StoreCards from "./StoreCards.vue";

export default {
    components: {
        ChatOption,
        StoreCards,
    },
    mixins: [darkModeMixin],
    props: {
        title: {
            type: String,
            default: '',
        },
        options: {
            type: Array,
            default: () => [],
        },
        selected: {
            type: String,
            default: '',
        },
        hideFields: {
            type: Boolean,
            default: false,
        },
        contentType : {
            type : String,
            default : '',
        },
        isPopup : {
          type: Boolean,
          default: false,
        }
    },
    data() {
        return {
            quantity: 0,
            productsItems : [],
            selectedIndex: -1,
        };
    },
    computed : {
        ...mapGetters({
            productSize: 'appConfig/productSize',
            senderId: 'appConfig/senderId',
            conversationId: 'appConfig/conversationId',
            accountId: 'appConfig/accountId'
        }),
        activatedIndex() {
          return this.selectedIndex;
        }
    },
    methods: {
        isSelected(option) {
            return this.selected === option.id;
        },
        onClick(selectedOption) {
            this.$emit('click', selectedOption);
        },
        onSelectIndexOption(selectedIndex, selectedOption) {
          this.selectedIndex = selectedIndex;
          this.$emit('selected-option', selectedOption);
        }
    },
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/variables.scss';
.has-selected {
  .option-button:not(.is-selected) {
    color: $color-light-gray;
    cursor: initial;
  }
}
</style>
<style scoped lang="scss">
@import '~widget/assets/scss/variables.scss';

.options-message {
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  max-width: 19rem;
  border-radius: $space-small;
  overflow: hidden;
  .title {
    font-size: $font-size-default;
    font-weight: $font-weight-normal;
    margin-bottom: $space-smaller;
    line-height: 1.5;
  }
  .options {
    width: 100%;
    > li {
      list-style: none;
      padding: 0;
    }
  }
}

.titleTxt{
  box-shadow: 0 .25rem 6px rgba(50,50,93,.08),0 1px 3px rgba(0,0,0,.05)!important;
  display: inline-block!important;
  font-size: .875rem!important;
  line-height: 1.5!important;
  padding: 0.75rem 1rem!important;
  text-align: left!important;
  word-break: break-word!important;
  background: #fff!important;
  border-radius: 0 1.25rem 1.25rem 0.25rem!important;
  color: black !important;
  white-space: pre-line !important;
}

.titleTxt::first-line{
  line-height: 0 !important;
}
.titleTxt:first-line{
  line-height: 0 !important;
}
</style>
