<template>
  <div class="settings--content">
    <div class="pre-chat--title">
      {{ $t('INBOX_MGMT.PRE_CHAT_FORM.DESCRIPTION') }}
    </div>
    <form @submit.prevent="updateInbox">
      <label class="medium-3 columns">
        {{ $t('INBOX_MGMT.PRE_CHAT_FORM.ENABLE.LABEL') }}
          <multiselect-dropdown
                  :options="preChatOptions"
                  :selected-item="preChatFormEnabled"
                  :multiselector-title="$t('INBOX_MGMT.PRE_CHAT_FORM.ENABLE.LABEL')"
                  :multiselector-placeholder="$t('INBOX_MGMT.PRE_CHAT_FORM.ENABLE.LABEL')"
                  :add-agent="true"
                  :has-thumbnail="false"
                  @click="selectPreChatOption"
          />
      </label>
      <div v-if="preChatFormEnabled.value">
        <label class="columns medium-8  large-8">
          <label>
            {{ $t('INBOX_MGMT.PRE_CHAT_FORM.PRE_CHAT_MESSAGE.LABEL') }}
          </label>
          <woot-message-editor
            v-model="preChatMessage"
            class="message-editor"
            :placeholder="
              $t('INBOX_MGMT.PRE_CHAT_FORM.PRE_CHAT_MESSAGE.PLACEHOLDER')
            "
          />
        </label>
        <div class="columns medium-8  large-8 pre-chat-fields">
          <label>{{ $t('INBOX_MGMT.PRE_CHAT_FORM.SET_FIELDS') }}</label>
          <table class="table table-striped w-full">
            <thead class="thead-dark">
              <tr>
                <th scope="col" />
                <th scope="col" />
                <th scope="col">
                  {{ $t('INBOX_MGMT.PRE_CHAT_FORM.SET_FIELDS_HEADER.KEY') }}
                </th>
                <th scope="col">
                  {{ $t('INBOX_MGMT.PRE_CHAT_FORM.SET_FIELDS_HEADER.TYPE') }}
                </th>
                <th scope="col">
                  {{
                    $t('INBOX_MGMT.PRE_CHAT_FORM.SET_FIELDS_HEADER.REQUIRED')
                  }}
                </th>
                <th scope="col">
                  {{ $t('INBOX_MGMT.PRE_CHAT_FORM.SET_FIELDS_HEADER.LABEL') }}
                </th>
                <th scope="col">
                  {{
                    $t(
                      'INBOX_MGMT.PRE_CHAT_FORM.SET_FIELDS_HEADER.PLACE_HOLDER'
                    )
                  }}
                </th>
              </tr>
            </thead>
            <pre-chat-fields
              :pre-chat-fields="preChatFields"
              @update="handlePreChatFieldOptions"
              @drag-end="changePreChatFieldFieldsOrder"
            />
          </table>
        </div>
      </div>
      <woot-submit-button
        style="height: 3.9rem !important;"
        :button-text="
          $t('INBOX_MGMT.SETTINGS_POPUP.UPDATE_PRE_CHAT_FORM_SETTINGS')
        "
        :loading="uiFlags.isUpdating"
      />
    </form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import PreChatFields from './PreChatFields.vue';
import { getPreChatFields, standardFieldKeys } from 'dashboard/helper/preChat';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue'  ;
export default {
  components: {
    PreChatFields,
    WootMessageEditor,
    MultiselectDropdown
  },
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      preChatFormEnabled: {
          id: 1,
          value: false,
          name: this.$t('INBOX_MGMT.PRE_CHAT_FORM.ENABLE.OPTIONS.DISABLED'),
      },
      preChatMessage: '',
      preChatFields: [],
      preChatOptions: [
        {
           id: 1,
           value: false,
           name: this.$t('INBOX_MGMT.PRE_CHAT_FORM.ENABLE.OPTIONS.DISABLED'),
        },
        {
           id: 2,
           value: true,
           name: this.$t('INBOX_MGMT.PRE_CHAT_FORM.ENABLE.OPTIONS.ENABLED'),
        }
      ],
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      customAttributes: 'attributes/getAttributes',
    }),
    preChatFieldOptions() {
      const { pre_chat_form_options: preChatFormOptions } = this.inbox;
      return getPreChatFields({
        preChatFormOptions,
        customAttributes: this.customAttributes,
      });
    },
  },
  watch: {
    inbox() {
      this.setDefaults();
    },
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      const { pre_chat_form_enabled: preChatFormEnabled } = this.inbox;
      this.preChatFormEnabled = preChatFormEnabled === false
          ? {
              id: 1,
              value: false,
              name: this.$t('INBOX_MGMT.PRE_CHAT_FORM.ENABLE.OPTIONS.DISABLED'),
          }
          : {
              id: 2,
              value: true,
              name: this.$t('INBOX_MGMT.PRE_CHAT_FORM.ENABLE.OPTIONS.ENABLED'),
          } ;;
      const {
        pre_chat_message: preChatMessage,
        pre_chat_fields: preChatFields,
      } = this.preChatFieldOptions || {};
      let preChatMsg = this.$root.$i18n.locale === 'fr' ? 'Partager vos informations' : 'Share your information';
      this.preChatFieldOptions.pre_chat_message = preChatMsg ;
      this.preChatMessage = preChatMsg;
      this.preChatFields = preChatFields;
    },
    isFieldEditable(item) {
      return !!standardFieldKeys[item.name] || !item.enabled;
    },
    handlePreChatFieldOptions(event, type, item) {
      this.preChatFields.forEach((field, index) => {
        if (field.name === item.name) {
          this.preChatFields[index][type] = !item[type];
        }
      });
    },

    changePreChatFieldFieldsOrder(updatedPreChatFieldOptions) {
      this.preChatFields = updatedPreChatFieldOptions;
    },

    async updateInbox() {
      try {
        const payload = {
          id: this.inbox.id,
          formData: false,
          channel: {
            pre_chat_form_enabled: this.preChatFormEnabled.value,
            pre_chat_form_options: {
              pre_chat_message: this.preChatMessage,
              pre_chat_fields: this.preChatFields,
            },
          },
        };
        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      }
    },
    selectPreChatOption(option) {
        if (this.preChatFormEnabled && this.preChatFormEnabled.value === option.value) {
            if (this.preChatFormEnabled.value === true) {
                this.preChatFormEnabled = {
                    id: 1,
                    value: false,
                    name: this.$t('INBOX_MGMT.PRE_CHAT_FORM.ENABLE.OPTIONS.DISABLED'),

                };
            }
            else {
                this.preChatFormEnabled = {
                    id: 2,
                    value: true,
                    name: this.$t('INBOX_MGMT.PRE_CHAT_FORM.ENABLE.OPTIONS.ENABLED'),
                };
            }
        }
        else {
            this.preChatFormEnabled = option;
        }
    }
  },
};
</script>
<style scoped lang="scss">
.settings--content {
  font-size: var(--font-size-default);
}
.pre-chat--title {
  margin: var(--space-medium) 0 var(--space-slab);
}

.submit-button {
  margin-top: var(--space-normal);
}
.pre-chat-fields {
  margin-top: var(--space-normal);
}
</style>
