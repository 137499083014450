<template>
    <div class="position-relative">
      <svg v-tooltip.right="$t('CHAT_LIST.SORT_TOOLTIP_LABEL')" style="outline: none;" @click="toggleDropdown" variant="smooth" class="cursor-pointer" width="36" height="30" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 16C0 8.45753 0 4.68629 2.34315 2.34315C4.68629 0 8.45753 0 16 0H28C35.5425 0 39.3137 0 41.6569 2.34315C44 4.68629 44 8.45753 44 16V28C44 35.5425 44 39.3137 41.6569 41.6569C39.3137 44 35.5425 44 28 44H16C8.45753 44 4.68629 44 2.34315 41.6569C0 39.3137 0 35.5425 0 28V16Z" fill="#EDECF6"/>
        <path d="M19.01 31.25C18.82 31.25 18.63 31.18 18.48 31.03L13.47 26.02C13.18 25.73 13.18 25.25 13.47 24.96C13.76 24.67 14.24 24.67 14.53 24.96L19.54 29.97C19.83 30.26 19.83 30.74 19.54 31.03C19.39 31.17 19.2 31.25 19.01 31.25Z" fill="#483A9D"/>
        <path d="M19.01 31.25C18.6 31.25 18.26 30.91 18.26 30.5V13.5C18.26 13.09 18.6 12.75 19.01 12.75C19.42 12.75 19.76 13.09 19.76 13.5V30.5C19.76 30.91 19.42 31.25 19.01 31.25Z" fill="#483A9D"/>
        <path d="M30.01 19.26C29.82 19.26 29.63 19.19 29.48 19.04L24.47 14.03C24.18 13.74 24.18 13.26 24.47 12.97C24.76 12.68 25.24 12.68 25.53 12.97L30.54 17.98C30.83 18.27 30.83 18.75 30.54 19.04C30.39 19.19 30.2 19.26 30.01 19.26Z" fill="#483A9D"/>
        <path d="M24.99 31.25C24.58 31.25 24.24 30.91 24.24 30.5V13.5C24.24 13.09 24.58 12.75 24.99 12.75C25.4 12.75 25.74 13.09 25.74 13.5V30.5C25.74 30.91 25.41 31.25 24.99 31.25Z" fill="#483A9D"/>
      </svg>
        <div
                v-if="showActionsDropdown"
                v-on-clickaway="closeDropdown"
                class="dropdown-pane dropdown-pane--open basic-filter"
        >
            <div class="filter__item">
                <span>{{ this.$t('CHAT_LIST.CHAT_SORT.STATUS') }}</span>
                <!--        <filter-item
                          type="status"
                          :selected-value="chatStatus"
                          :items="chatStatusItems"
                          path-prefix="CHAT_LIST.CHAT_STATUS_FILTER_ITEMS"
                          @onChangeFilter="onChangeFilter"
                        />-->
                <multiselect-dropdown
                        :options="chatItems"
                        :selected-item="chatStatus"
                        :has-thumbnail="false"
                        :is-conv="true"
                        @click="changeChatStatusType"
                />
            </div>
            <div class="filter__item">
                <span>{{ this.$t('CHAT_LIST.CHAT_SORT.ORDER_BY') }}</span>
                <!--        <filter-item
                          type="sort"
                          :selected-value="chatSortFilter"
                          :items="chatSortItems"
                          path-prefix="CHAT_LIST.CHAT_SORT_FILTER_ITEMS"
                          @onChangeFilter="onChangeFilter"
                        />-->
                <multiselect-dropdown
                        :options="chatSort"
                        :selected-item="sortFilter"
                        :has-thumbnail="false"
                        :is-conv="true"
                        @click="changeChatSortFilter"
                />
            </div>
        </div>
    </div>
</template>

<script>
import wootConstants from 'dashboard/constants/globals';
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import FilterItem from './FilterItem';
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue' ;

export default {
    components: {
        FilterItem,
        MultiselectDropdown,
    },
    mixins: [clickaway],
    data() {
        return {
            showActionsDropdown: false,
            chatStatusItems: this.$t('CHAT_LIST.CHAT_STATUS_FILTER_ITEMS'),
            chatSortItems: this.$t('CHAT_LIST.CHAT_SORT_FILTER_ITEMS'),
        };
    },
    props: {
      accountId: {
        type: Number,
        default: 0,
      } ,
    },
    computed: {
        ...mapGetters({
            chatStatusFilter: 'getChatStatusFilter',
            chatSortFilter: 'getChatSortFilter',
        }),
        chatItems() {
            return this.chatStatusItems;
        },
        chatSort() {
            return this.chatSortItems;
        },
        chatStatus() {
            /*return this.chatStatusFilter || wootConstants.STATUS_TYPE.OPEN;*/
            const defaultStatus = { "name": this.$t('CHAT_LIST.CHAT_STATUS_FILTER_ITEMS')[0].name, "id": "1", "value": "open" };
            const itemStatus = this.$t('CHAT_LIST.CHAT_STATUS_FILTER_ITEMS').find(item => item.value === this.chatStatusFilter) ;
            return itemStatus || defaultStatus;
        },
        sortFilter() {
            const defaultSort = { "name": this.$t('CHAT_LIST.CHAT_SORT_FILTER_ITEMS')[0].name, "id": "1", "value": "latest" };
            const itemSort = this.$t('CHAT_LIST.CHAT_SORT_FILTER_ITEMS').find(item => item.value === this.chatSortFilter);
            /*return this.chatSortFilter || defaultSort;*/
            return itemSort || defaultSort;
        },
    },
    methods: {
        onTabChange(value) {
            this.$emit('changeFilter', value);
            this.closeDropdown();
        },
        toggleDropdown() {
            this.showActionsDropdown = !this.showActionsDropdown;
        },
        closeDropdown() {
            this.showActionsDropdown = false;
        },
        onChangeFilter(type, value) {
            this.$emit('changeFilter', type, value);
        },
        changeChatStatusType(status) {
            this.$emit('changeStatusFilter', status);
        },
        changeChatSortFilter(sort) {
            this.$emit('changeSortFilter', sort);
        }
    },
};
</script>
<style lang="scss" scoped>
.basic-filter {
  margin-top: var(--space-smaller);
  padding: var(--space-normal);
  right: 0;
  width: 21rem;

  span {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
  }

  .filter__item {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &:last-child {
      margin-top: var(--space-normal);
    }

    span {
      font-size: var(--font-size-mini);
    }
  }
}

.icon {
  margin-right: var(--space-smaller);
}

.dropdown-icon {
  margin-left: var(--space-smaller);
}
</style>
